<script setup lang="ts">
import SignaturePad from "~/components/Common/SignaturePad.vue";

definePageMeta({
  layout: "home",
});
const colorMode = useColorMode();
colorMode.preference = "light";

const model = ref("");
useHead({
  title: "QuoteIQ CRM",
});
</script>

<template>
  <div class="h-screen">
    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-900"></label>
    </div>
  </div>
</template>
